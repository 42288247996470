









































































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import RetirementFundContributionsViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/retirement-fund/retirement-fund-contributions-view-model';

@Component({
  name: 'RetirementFundContributions',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RetirementFundContributions extends Vue {
  view_model = Vue.observable(
    new RetirementFundContributionsViewModel(this),
  );

  async mounted() {
    await this.view_model.initialize();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }
}
