import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import GetRetirementInvestorGoalCalculatorQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/get-retirement-investor-goal-calculator-query';
import CalculateRetirementGoalMinimumMonthlyPensionQuery
  from '@/modules/flagship/retirement-investor-goal-calculator/application/queries/calculate-retirement-goal-minimum-monthly-pension-query';

// Domain
import { RetirementInvestorGoalCalculatorDto }
  from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-investor-goal-calculator-dto';
import {
  RetirementGoalMinimumMonthlyPensionCalculationDto,
} from '@/modules/flagship/retirement-investor-goal-calculator/domain/dtos/retirement-goal-minimum-monthly-pension-dto';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RetirementFundContributionsViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_retirement_investor_goal_calculator_query!:
    GetRetirementInvestorGoalCalculatorQuery;

  @Inject(TYPES.CALCULATE_RETIREMENT_GOAL_MINIMUM_MONTHLY_PENSION_QUERY)
  private readonly calculate_minimum_monthly_pension!:
    CalculateRetirementGoalMinimumMonthlyPensionQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.retirement-fund.contributions';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  timer?: NodeJS.Timer;

  readonly min_contribution = 500;

  defined_by = 'by_time';

  contribution = '100';

  monthly_desired_pension = '';

  initial_amount = 0;

  pension_range_adjusted = 0;

  is_define_by_contribution = false;

  is_valid_form = false;

  retirement_age = 0;

  is_loading = false;

  is_min_pension_loading = false;

  associated_product_id = '';

  monthly_required_amount = 0;

  calculated_pension = 0;

  accumulated_amount = 0;

  target_amount = 0;

  retirement_goal_calculator_dto: RetirementInvestorGoalCalculatorDto = {
    associated_product_id: '',
    defined_by: '',
    issued_age: 0,
    initial_amount: 0,
    retirement_age_adjusted: 0,
    monthly_pension_adjusted: 0,
    monthly_required_amount: 0,
  };

  min_pension = 1;

  input_rules = {
    contribution: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$500.00 MXN', this.min_contribution)],
    pension: [
      requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''),
        this.getAmountFormatted(this.min_pension), this.min_pension)],
  };

  investor_goal_state = this.create_investor_goal_state_manager.state;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.setInitialValues();
    await this.calculateMinimumAccumulatedAmount();
    this.delay();
  }

  get target_amount_formatted() {
    return this.getAmountFormatted(this.target_amount);
  }

  get current_amount_formatted() {
    return this.getAmountFormatted(this.initial_amount);
  }

  get you_must_save() {
    return this.getAmountFormatted((this.is_define_by_contribution) ? this
      .calculated_pension : this.amount_you_must_save);
  }

  get amount_you_must_save() {
    return parseCurrencyToNumber(this.monthly_required_amount.toFixed(2));
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading || this.is_min_pension_loading;
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    this.defined_by = (!this.is_define_by_contribution) ? 'by_time' : 'by_contribution';
    if ((this.is_define_by_contribution && is_option_by_time) || (!this
      .is_define_by_contribution && !is_option_by_time)) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  delay = () => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (this.is_valid_form) {
        await this.loadMonthlyRequiredAmount();
      } else this.is_loading = false;
    }, 2000);
  }

  setInitialValues = async () => {
    try {
      this.retirement_age = this.investor_goal_state.retirement_fund.retirement_age_adjusted;

      if (this.investor_goal_state.is_defined_by_contribution) {
        this.contribution = this.investor_goal_state.investor_goal.monthly_required_amount
          .toString();
      } else {
        this.monthly_desired_pension = Math.ceil(
          this.investor_goal_state.retirement_fund.monthly_pension_adjusted,
        ).toString();
      }

      this.defined_by = this.investor_goal_state.investor_goal.defined_by || 'by_pension';
      this.is_define_by_contribution = this.investor_goal_state.is_defined_by_contribution;
      this.initial_amount = this.investor_goal_state.investor_goal.initial_amount_adjusted;

      this.retirement_goal_calculator_dto = {
        associated_product_id: this.investor_goal_state.associated_product_id,
        defined_by: this.defined_by,
        issued_age: this.investor_goal_state.retirement_fund.issued_age,
        retirement_age_adjusted: this.retirement_age,
        initial_amount: this.initial_amount,
        monthly_pension_adjusted: this.investor_goal_state.retirement_fund.monthly_pension_adjusted,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount,
      };
      await this.loadMonthlyRequiredAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_fund_information'));
    }
  }

  definedByChange = () => {
    this.monthly_desired_pension = '0.00';
    this.contribution = '0.00';
    this.target_amount = 0;
    this.calculated_pension = 0;
    this.monthly_required_amount = 0;
  }

  calculateMinimumAccumulatedAmount = async () => {
    try {
      this.is_loading = true;
      if (this.investor_goal_state.investor_goal.initial_amount_adjusted > 0) {
        const interest_rate = this.investor_goal_state
          .associated_product_interest_rate / 100;
        const payload: RetirementGoalMinimumMonthlyPensionCalculationDto = {
          initial_amount: this.investor_goal_state.investor_goal.initial_amount_adjusted,
          monthly_required_amount: 0,
          retirement_range_adjusted: this.investor_goal_state.retirement_fund
            .retirement_range_adjusted * 12,
          pension_range_adjusted: this.investor_goal_state
            .retirement_fund.pension_range_adjusted * 12,
          interest_rate,
        };
        const {
          minimum_monthly_pension,
        } = await this.calculate_minimum_monthly_pension.execute(payload);
        this.min_pension = Math.ceil(parseFloat(minimum_monthly_pension));
      } else {
        this.min_pension = 1;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.is_loading = false;
    }
  }

  loadMonthlyRequiredAmount = async () => {
    try {
      this.is_loading = true;
      this.retirement_goal_calculator_dto.defined_by = (this.is_define_by_contribution) ? 'contribution' : 'pension';
      this.retirement_goal_calculator_dto.monthly_pension_adjusted = (this
        .is_define_by_contribution) ? 0 : parseCurrencyToNumber(this.monthly_desired_pension);
      this.retirement_goal_calculator_dto.monthly_required_amount = (this
        .is_define_by_contribution) ? parseCurrencyToNumber(this.contribution) : 0;
      const { retirement_calculation_data } = await this
        .get_retirement_investor_goal_calculator_query.execute(this.retirement_goal_calculator_dto);

      const {
        monthly_pension_adjusted,
        monthly_required_amount,
        accumulated_amount,
      } = retirement_calculation_data;

      this.monthly_required_amount = monthly_required_amount;
      this.calculated_pension = monthly_pension_adjusted;
      this.accumulated_amount = accumulated_amount;
      this.target_amount = accumulated_amount;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_monthly_required_amount'));
    } finally {
      this.is_loading = false;
    }
  };

  setState = async () => {
    this.investor_goal_state.investor_goal.accumulated_amount = this.accumulated_amount;
    if (this.is_define_by_contribution) {
      this.investor_goal_state.investor_goal.monthly_required_amount = this.monthly_required_amount;
      this.investor_goal_state.retirement_fund.monthly_pension_adjusted = 0;
    } else {
      this.investor_goal_state.retirement_fund.monthly_pension_adjusted = parseCurrencyToNumber(this
        .monthly_desired_pension);
      this.investor_goal_state.investor_goal.monthly_required_amount = this.monthly_required_amount;
    }
    this.investor_goal_state.retirement_fund.calculated_pension = this
      .calculated_pension;
    this.investor_goal_state.investor_goal.defined_by = this.defined_by;
    this.investor_goal_state.is_defined_by_contribution = this.is_define_by_contribution;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    try {
      await this.setState();
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.save'));
    }
  }
}
